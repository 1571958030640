<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Checkins" subtitle="View checkin history for all attendees." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.types" text="Type" tooltip="Filter by type" :options="filterTypeOptions" />
		<app-content-filters-date mode="datetime" v-model="filter.from" tooltip="Filter from date" text="From" :max="filter.to" />
		<app-content-filters-date mode="datetime" v-model="filter.to" tooltip="Filter to date" text="To" :min="filter.from" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no checkins found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="No." />
				<app-table-column text="Ref." />
				<app-table-column text="Name" />
				<app-table-column text="Type" />
				<app-table-column text="Day" />
				<app-table-column text="Time" />
				<app-table-column align="center" text="Badge" />
				<app-table-column align="center" text="Add-ons" />

			</app-table-head>

			<app-table-body>

				<app-table-row :layout="layout" v-for="item in collection" :key="item.id" v-on:click="onEditClick(item.badge.id)">

					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.reference_random" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.badge.type" />
					<app-table-cell-text :text="item.date | localDate('dddd')" />
					<app-table-cell-text :text="item.date | localDate('HH:mm')" />
					<app-table-cell-text align="center" text="-" v-if="!item.received.badge" />
					<app-table-cell-icon align="center" icon="check" v-if="item.received.badge" />
					<app-table-cell-text align="center" :text="item.received.addons" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				types: []
			},
			filter: {
				types: [],
				from: 0,
				to: 0
			},
			layout: '48px 72px auto 200px 80px 60px 80px 80px',
			endpoint: 'convention/badges/checkins',
			live: 'convention/badges/checkins',
			edit: 'Convention.Badges.Holders.Edit'
		}

	},

	computed: {

		filterTypeOptions: function() {

			var options = []

			this.$_.each(this.references.types, function(type) {

				options.push({
					value: type.id,
					text: type.name
				})
			
			})

			return this.$_.sortBy(options, 'text')

		}

	}

}

</script>

<style scoped>

</style>